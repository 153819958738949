import { FORCE_ENABLE_MAESTRO_LOGIN } from 'config';
import { DISMISS_MODAL, SHOW_MODAL } from './actions';
import { IModalAction } from './actions';
import { ModalPayload, ModalKinds } from './types';

export type IModalsState = ModalPayload[];

export const getInitialState = (): IModalsState => {
  return FORCE_ENABLE_MAESTRO_LOGIN ? [{ kind: ModalKinds.login, data: { page: 'LOGIN' } }] : [];
};

const modalsReducer = (
  state: IModalsState = getInitialState(),
  action: IModalAction,
): IModalsState => {
  switch (action.type) {
    case DISMISS_MODAL: {
      const kind = action.payload;
      if (kind) {
        const reversedModals = [...state].reverse();
        const lastOfKindIndex = reversedModals.findIndex(modal => modal.kind === kind);
        reversedModals.splice(lastOfKindIndex, 1);
        return reversedModals.reverse();
      }
      return state.slice(0, -1);
    }
    case SHOW_MODAL: {
      const modalConfig = action.payload;
      return [...state, modalConfig];
    }
    default: {
      return state;
    }
  }
};

export default modalsReducer;
