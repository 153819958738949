import { MOCK_NAVIGATION } from 'components/admin-bridge/SiteNavigation/mock';
import { NAVIGATION } from 'config';
import INavigation from 'models/INavigation';
import { createSelector } from 'reselect';
import { isUserAdmin } from 'services/auth';
import type IState from 'services/state';
import { lazyFn } from 'utils/lazy';

export const getNavigations = (state: IState) => state.navigationv2.navigations;

export const getPageNavigationId = (state: IState) => state.app.object?.data?.navigationId;

export const getDefaultNavigation = createSelector(
  getNavigations,
  (navigations) => {
    const defaultNavigation = navigations.find((nav) => nav.type === 'default');
    return defaultNavigation || NAVIGATION || MOCK_NAVIGATION;
  },
);

const findAppliedNavigation = (navigations: INavigation[], defaultNavigation: INavigation, navigationId?: string) => {
  const pageNavigation = (() => {
    if (navigationId) {
      return navigations.find((nav) => nav._id === navigationId);
    } else {
      return defaultNavigation;
    }
  })();
  return pageNavigation || NAVIGATION || defaultNavigation;
};

export const getNavigationAppliedInThePage = lazyFn(
  () => createSelector(
    getNavigations,
    getPageNavigationId,
    getDefaultNavigation,
    (navigations, navigationId, defaultNavigation) => {
      return findAppliedNavigation(navigations, defaultNavigation, navigationId);
    },
  ),
);

export const getNavigationSelectedInTheDropdown = lazyFn(
  () => createSelector(
    getNavigations,
    getNavigationAppliedInThePage,
    (state: IState) => state.navigationv2.navigationSelectedInTheDropdown,
    (navigations, appliedNavigation, navigationSelectedInTheDropdown) => {
      return navigations.find((nav) => nav._id === navigationSelectedInTheDropdown) || appliedNavigation;
    },
  ),
);

export const getEditableFolderIdInSiteNavigation = (state: IState) => state.navigationv2.editableFolderIdInSiteNavigation;

export const getEditableFolderIdInNavigationBlock = (state: IState) => state.navigationv2.editableFolderIdInNavigationBlock;

export const getSearchText = (state: IState) => state.navigationv2.searchText;

export const getCreateLinkForCustomNavigation = (state: IState) => state.navigationv2.links;

export const getOpenNavigation = (state: IState) => state.navigationv2.openNavigation;

export const getCustomNavigationCreationLoading = (state: IState) => state.navigationv2.customNavigationCreationLoading;

export const getAreAllNavigationsAlreadyFetched = (state: IState) => state.navigationv2.areAllNavigationsAlreadyFetched;
