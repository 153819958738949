import IState from 'services/state';
import { getQuery } from 'services/app-router/selectors/common';

export const isVideoOnlyMode = (state: IState) => Boolean(Number(getQuery(state).video_only));
export const layoutWantsVideo = ({ userLayout }: IState) => userLayout.wantsVideo;
export const isVideoEmbed = (state) => {
  const embed = getQuery(state).embed;
  if (typeof embed === 'string') {
    return embed.toLowerCase() === 'video';
  } else if (embed instanceof Array && embed.length) {
    return embed.some(v => v.toLowerCase() === 'video');
  }
  return false;
};

export const getIsOrientationLocked = (state: IState) => state.userLayout.orientationLocked;
