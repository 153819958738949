import { createSelector } from 'reselect';
import { getDocument } from 'services/realtime/selectors';
import { ModeTypes } from 'services/admin/constants';
import IStream from 'models/IStream';
import { IVideo } from 'models';
import IState from '../state';
import { StreamSource } from './constants';
import { getDefaultStreamingProvider, getObject, getSite, getStreamingProviderAccountId } from 'services/app/selectors';
import { lazyFn } from 'utils/lazy';
import type { IStreamProviderAccount } from 'models/IStreamProviderAccount';

export const livestreamRenderer = lazyFn(
  () => createSelector(
    [getObject],
    (object) => object?.renderers?.livestream,
  ),
);

export const getMaestroStreamId = lazyFn(
  () => createSelector(
    livestreamRenderer,
    (renderer) => renderer?.streamId,
  ),
);

export const getRealtimeStream = lazyFn(
  () => (state: IState): IStream | null | undefined => {
    const currentStreamId = getMaestroStreamId(state);
    if (currentStreamId) {
      return getDocument(state, 'stream', currentStreamId);
    }
    return null;
  },
);

export const isLivestreamPublished = lazyFn(
  () => createSelector(
    livestreamRenderer,
    (renderer) => Boolean(renderer?.livestreamPublished),
  ),
);

export const isLivestreamPreviewActive = lazyFn(
  () => createSelector(
    [(state: IState) => state.admin.livestreamPreviewActive],
    (livestreamPreviewActive: boolean) => livestreamPreviewActive,
  ),
);

export const getLivestreamProvider = lazyFn(
  () => createSelector(
    [getSite],
    (site) => site.settings?.defaultStreamingProvider || 'mux',
  ),
);

export const getLivestreamVideoId = lazyFn(
  () => createSelector(
    livestreamRenderer,
    (renderer) => renderer?.videoId,
  ),
);

export const getLivestreamVideo = lazyFn(
  () => (state: IState) => {
    const videoId = getLivestreamVideoId(state);
    return getDocument(state, 'video', videoId!) as IVideo | null | undefined;
  },
);

export const isLivestreamAvailable = lazyFn(
  () => (state: IState) => Boolean(getMaestroStreamId(state)),
);

export const getLivestreamError = lazyFn(
  () => (state: IState) => state.livestream.error,
);

export const getMetadataStatus = lazyFn(
  () => (state: IState) => state.livestream.metadataStatus,
);

export const getLivestreamLiveBroadcastId = lazyFn(
  () => (state: IState) => {
    const video = getLivestreamVideo(state);
    return video?.liveBroadcastId;
  },
);

const getLivestreamSource = lazyFn(
  () => createSelector(
    livestreamRenderer,
    (renderer) => renderer?.source,
  ),
);

export const getStreamSource = lazyFn(
  () => (state: IState): StreamSource | undefined => {
    const publishedSource = getLivestreamSource(state);
    if (publishedSource) return publishedSource;

    const adminMode = state.admin.adminMode;

    if (adminMode === ModeTypes.MAESTRO_STREAM)
      return StreamSource.THIRD_PARTY;

    if (adminMode === ModeTypes.STUDIO_STREAM)
      return StreamSource.STUDIO;

    return undefined;
  },
);

export const isPublishedWithMaestroStream = lazyFn(
  () => (state: IState) => {
    const isPublished = isLivestreamPublished(state);

    if (!isPublished) {
      return false;
    }

    const source = getStreamSource(state);
    return source === StreamSource.THIRD_PARTY;
  },
);

export const isPublishedWithStudioStream = lazyFn(
  () => (state: IState) => {
    const isPublished = isLivestreamPublished(state);

    if (!isPublished) {
      return false;
    }

    const source = getStreamSource(state);
    return source === StreamSource.STUDIO;
  },
);

export const getStudioSessionReady = lazyFn(
  () => (state: IState) =>
    state.livestream.studioSessionReady,
);

export const getStudioGuestModeInviteCode = lazyFn(
  () => (state: IState) =>
    (state.livestream?.studioGuestModeInviteCode || null),
);

export const getPendingStudioGuestInviteAccountId = lazyFn(
  () => (state: IState) => state.livestream?.pendingGuestAccountId,
);

export const getStudioSessionId = lazyFn(
  () => createSelector(
    livestreamRenderer,
    (renderer) => renderer?.studioSessionId,
  ),
);

export const isStreamBeingCreatedForCurrentChannel = lazyFn(
  () => createSelector(
    livestreamRenderer,
    (renderer) => renderer?.status === 'creating',
  ),
);

export const getStreamProviderAccount = lazyFn(
  () => (state: IState): IStreamProviderAccount | undefined => {
    const accountId = getStreamingProviderAccountId(state);

    if (!accountId)
      return undefined;

    const provider = getDefaultStreamingProvider(state);
    const account = getDocument(state, 'streamprovideraccount', accountId) as IStreamProviderAccount | undefined;

    if (account?.providerName === provider)
      return account;
  },
);
