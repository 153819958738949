import { createSelector } from 'reselect';
import { isEditMode, parseAdminQuery } from 'services/admin/selectors';
import { getQuery } from 'services/app-router/selectors/common';
import { getInitialSidebarWidth } from 'services/device/selectors/common';
import { isVideoOnlyMode, layoutWantsVideo } from 'services/user-layout/selectors/common';
import { isLandingPageType } from 'services/app/selectors/common';
import { getCustomPanels } from 'services/custom-panels/selectors';
import { shouldRenderChannelGate } from 'services/gate/selectors/common';
import { isAdminViewingAccessTab } from 'services/gate/selectors';
import { lazyFn } from 'utils/lazy';
import { SHOPIFY_BLOCK_PANEL_ID } from 'components/objects/PanelV2/constants';

export { isVideoOnlyMode, layoutWantsVideo };

export const getUserLayout = lazyFn(() => createSelector(
  (state) => state.userLayout, (layout) => layout,
));
export const isTheaterMode = state => state.userLayout.theaterMode;
export const displayGDPR = state => state.userLayout.displayGRPR;
export const displaySuscriptionGate = state => state.userLayout.subscriptionGate;
export const displayPasswordGate = state => state.userLayout.passwordGate;
export const displayLoginGate = state => state.userLayout.loginGate;
export const displayAmazonBenefitGate = state => state.userLayout.amazonBenefitGate;

export const getActivePanelsV2 = lazyFn(() => createSelector(
  [(state) => state.userLayout, shouldRenderChannelGate],
  (layout, isChannelGateOn) => isChannelGateOn ? [] : layout.activePanels,
));

export const hasActivePanels = lazyFn(() => createSelector(
  [getActivePanelsV2, (state) => state.customPanels],
  (activePanels, customPanels) => {
    return Boolean(activePanels?.length > 0 || customPanels.length);
  },
));

export const getShopifyBlockPanel = lazyFn(() => createSelector(
  [getActivePanelsV2],
  (activePanels) => {
    return activePanels.find(panel => panel.renderer?.panelType === SHOPIFY_BLOCK_PANEL_ID);
  },
));

// legacy selector
export const isEmbed = (state) => Boolean(Number(getQuery(state).embed));

export const isOverlayEmbed = (state) => Boolean(getQuery(state).embed?.toLowerCase() === 'overlays');

export const isVideoEmbed = (state) => Boolean(getQuery(state).embed?.toLowerCase() === 'video');

export const isTheaterEmbed = (state) => Boolean(getQuery(state).embed?.toLowerCase() === 'theater') || isTheaterMode(state);

export const isChromaKeyMode = (state) => Boolean(Number(getQuery(state).chroma_key));

export const isMobilePanelEmbed = (state) => Boolean(getQuery(state).embed?.toLowerCase() === 'panels-mobile');

export const isCustomReceipt = (state) => Boolean(getQuery(state).receiptnumber);

export const getCustomReceipt = (state) => String(getQuery(state).receiptnumber);

const wantsSidebar = ({ userLayout }) => userLayout.wantsSidebar;

// TODO: Rewrite all of this!! Embed and video only mode should fully unmount the sidebar, not just
//       hide it... And get rid of these weird pseudo-enums. Argh I am so triggered. - Andy
export const getPanelState = lazyFn(
  () => createSelector(
    [
      wantsSidebar,
      hasActivePanels,
      isEmbed,
      isOverlayEmbed,
      isVideoEmbed,
      isVideoOnlyMode,
      isEditMode,
      isAdminViewingAccessTab,
    ],
    (
      sidebarOn,
      activePanels,
      embed,
      overlayEmbed,
      video,
      videoOnlyMode,
      inEditMode,
      isAdminAccessTabBeingRendered,
    ) => {
      if (isAdminAccessTabBeingRendered) {
        return {
          state: 'hiding',
        };
      }

      if (inEditMode) {
        return {
          state: 'showing',
        };
      }

      // no ability to show any panels? don't show/collapse sidebar
      if (!activePanels || embed || overlayEmbed || videoOnlyMode || video) {
        return {
          state: 'disabled',
        };
      }

      // user preference
      return {
        state: sidebarOn ? 'showing' : 'hiding',
      };
    },
  ),
);

export const isSidebarVisible = (state) => (
  (isLandingPageType(state) && !getCustomPanels(state).length) ?
    false : getPanelState(state).state === 'showing'
);

export const getCurrentSidebarWidth = ({ userLayout }) => userLayout.sidebarWidth;

export const getSidebarWidth = lazyFn(
  () => createSelector(
    [isSidebarVisible,
      parseAdminQuery,
      state => getInitialSidebarWidth(state),
    ],
    (sidebarVisible, query, currentSidebarWidth) => (
      sidebarVisible &&
      !query.admin
    ) ? currentSidebarWidth : 0,
  ),
);

export const isFullscreenActive = ({ userLayout }) => userLayout.viewMode === 'fullscreen';

export const isTheaterActive = ({ userLayout }) => userLayout.viewMode === 'theater';
