import { createSelector } from 'reselect';
import IState from 'services/state';
import { getDocumentPath } from 'utils';

export const getRealtimeDocuments = createSelector(
  ({ realtime: { documents } }: IState) => documents,
  (documents) => documents,
);

export const isDocumentLoaded = (state: IState, collection: string, id: string) =>
  state.realtime.documents[getDocumentPath(collection, id)] !== undefined;

export const getDocument = createSelector(
  (state: IState, collection: string, id: string) => state.realtime.documents[getDocumentPath(collection, id)],
  (document) => (document === undefined ? null : document),
);

export const isListening = (state: IState, collection: string, id: string) =>
  state.realtime.listeners[getDocumentPath(collection, id)] !== undefined;
