import IState from 'services/state';
import { getStudioGuestModeInviteCode } from 'services/livestream/selectors';
import { isUserAdmin as getUserAdmin } from 'services/auth/selectors';
import { createSelector } from 'reselect';
import { lazyFn } from 'utils/lazy';

export const isChannelGateEnabled = lazyFn(
  () => ({ app, gate }: IState): boolean => {
    if (gate?.adminGatePreview) {
      return true;
    }
    return app.object?.data?.gate?.active || false;
  },
);

export const isGateActive = lazyFn(
  () => ({ app, gate }: IState): boolean => {
    const channelId = app.object?._id;
    if (gate?.adminGatePreview) {
      return true;
    }
    return gate?.activeGateMap[channelId] || false;
  },
);

export const shouldRenderChannelGate = lazyFn(
  () => createSelector([
    getUserAdmin,
    isChannelGateEnabled,
    isGateActive,
    getStudioGuestModeInviteCode,
  ], (isUserAdmin: boolean, gateEnabled: boolean, gateActive: boolean, studioInviteCode: string | null) => {
    return !isUserAdmin && gateEnabled && gateActive && !studioInviteCode;
  }),
);

export const getAccountEntitlementsLoaded = lazyFn(
  () => (state: IState) => state.gate.accountEntitlementsLoaded,
);
