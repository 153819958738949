import IPanel from 'models/IPanel';
import { SIDEBAR_WIDTH } from 'style/constants';
import {
  ISetViewModePayload,
  IUserLayoutAction,
  SET_VIEW_MODE,
  UPDATE_VIDEO,
  UPDATE_WANTS_SIDEBAR,
  SET_THEATER_MODE,
  SET_GDPR,
  SET_SUBSCRIPTION_GATE,
  SET_LOGIN_GATE,
  SET_PASSWORD_GATE,
  SET_SIDEBAR_WIDTH,
  SET_ACTIVE_PANELS,
  SET_AMAZON_BENEFIT_GATE,
  SET_ORIENTATION_LOCKED,
} from './actions';

export interface IUserLayoutState {
  activePanels: IPanel<{}>[];
  amazonBenefitGate: boolean;
  displayGRPR: boolean;
  loginGate: boolean;
  orientationLocked: boolean;
  passwordGate: boolean;
  sidebarWidth: number;
  subscriptionGate: boolean;
  theaterMode: boolean;
  viewMode: ISetViewModePayload;
  wantsHeader: boolean;
  wantsSidebar: boolean;
  wantsVideo: boolean;
}

// user preferred states
// DOES NOT mean that the user will get that it's just a preference
export const INITIAL_STATE: IUserLayoutState = {
  sidebarWidth: SIDEBAR_WIDTH,
  viewMode: null,
  wantsHeader: true,
  wantsSidebar: true,
  wantsVideo: true,
  theaterMode: false,
  displayGRPR: true,
  subscriptionGate: true,
  passwordGate: true,
  loginGate: true,
  amazonBenefitGate: true,
  activePanels: [],
  orientationLocked: false,
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

const customPanels = (state: IUserLayoutState = INITIAL_STATE, action: IUserLayoutAction) => {
  switch (action.type) {
    case SET_ORIENTATION_LOCKED: {
      return {
        ...state,
        orientationLocked: action.payload,
      };
    }
    case UPDATE_WANTS_SIDEBAR:
      return {
        ...state,
        wantsSidebar: action.payload,
      };

    case UPDATE_VIDEO:
      return {
        ...state,
        wantsVideo: action.payload,
      };

    case SET_SIDEBAR_WIDTH:
      return {
        ...state,
        sidebarWidth: action.payload,
      };
    case SET_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload,
      };
    case SET_THEATER_MODE:
      return {
        ...state,
        theaterMode: action.payload,
      };
    case SET_GDPR:
      return {
        ...state,
        displayGRPR: action.payload,
      };
    case SET_SUBSCRIPTION_GATE:
      return {
        ...state,
        subscriptionGate: action.payload,
      };
    case SET_LOGIN_GATE:
      return {
        ...state,
        loginGate: action.payload,
      };
    case SET_PASSWORD_GATE:
      return {
        ...state,
        passwordGate: action.payload,
      };
    case SET_ACTIVE_PANELS:
      return {
        ...state,
        activePanels: action.payload,
      };
    case SET_AMAZON_BENEFIT_GATE:
      return {
        ...state,
        amazonBenefitGate: action.payload,
      };
    default:
      return state;
  }
};

export default customPanels;
