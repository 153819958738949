import { IAccountEntitlement } from './models';
import ISubscription from 'models/ISubscription';

export const SET_IS_GATE_ACTIVE = 'gate/SET_IS_GATE_ACTIVE';
export const SET_ENABLE_GATE_ERROR_MESSAGE_KEY = 'gate/SET_ENABLE_GATE_ERROR_MESSAGE_KEY';
export const SET_GATE_USER_AGE = 'gate/SET_GATE_USER_AGE';
export const SET_GATE_SUBSCRIPTIONS = 'gate/SET_GATE_SUBSCRIPTIONS';
export const SET_GATE_TRACKED = 'gate/SET_GATE_TRACKED';
export const SET_PASSWORD_ENTRY_VALID = 'gate/SET_PASSWORD_ENTRY_VALID';
export const SET_ACCESS_CODE_ENTRY_VALID = 'gate/SET_ACCESS_CODE_ENTRY_VALID';
export const SET_API_LOADING = 'gate/SET_API_LOADING';
export const SUBMIT_ACCESS_CODE = 'gate/SUBMIT_ACCESS_CODE';
export const SUBMIT_PASSWORD = 'gate/SUBMIT_PASSWORD';
export const SET_PREVIEW_DURATION = 'gate/SET_PREVIEW_DURATION';
export const SET_PREVIEW = 'gate/SET_PREVIEW';
export const FETCH_ACCOUNT_ENTITLEMENTS = 'gate/FETCH_ACCOUNT_ENTITLEMENTS';
export const SET_ACCOUNT_ENTITLEMENTS = 'gate/SET_ACCOUNT_ENTITLEMENTS';
export const UPDATE_ACCOUNT_ENTITLEMENT = 'gate/UPDATE_ACCOUNT_ENTITLEMENT';
export const SET_ADMIN_GATE_PREVIEW = 'gate/SET_ADMIN_GATE_PREVIEW';
export const SET_ACCOUNT_ENTITLEMENTS_LOADED = 'gate/SET_ACCOUNT_ENTITLEMENTS_LOADED';
export const SET_AMAZON_BENEFIT_CHECK_GATE = 'gate/SET_AMAZON_BENEFIT_CHECK_GATE';
export const SET_AMAZON_BENEFIT_REDEMPTION_GATE = 'gate/SET_AMAZON_BENEFIT_REDEMPTION_GATE';
export const CLEAR_AMAZON_BENEFIT_GATE_STATUS = 'gate/CLEAR_AMAZON_BENEFIT_GATE_STATUS';

interface ISetApiLoadingAction {
  payload: boolean;
  type: typeof SET_API_LOADING;
}

export type IGateAction =
  ISetGateActive |
  ISetEnableGateErrorMessageKeyAction |
  ISetGateUserAge |
  ISetGateTracked |
  ISubmitPasswordAction |
  ISetPasswordEntryValid |
  ISetApiLoadingAction |
  ISetPreview |
  ISetPreviewDuration |
  IFetchAccountEntitlement |
  ISetAccountEntitlement |
  ISetGateSubscriptionsAction |
  IUpdateAccountEntitlement |
  ISetAccessCodeEntryValid |
  ISetAdminGatePreview |
  ISetAccountEntitlementsLoaded |
  ISetAmazonBenefitCheckGate |
  ISetAmazonBenefitRedemptionGate |
  IClearAmazonBenefitGateStatus;

export interface ISubmitPaymentPayload {
  cardNumber: string;
  cvc: string;
  expMonth: string;
  expYear: string;
  paymentAmount: number;
  sku: string;
}
export interface ISubmitAcessCodePayload {
  accessCode: string;
  deviceId?: string;
  token?: string;
}

export interface ISetGateSubscriptionsPayload {
  subscriptions?: ISubscription[];
}

export interface ISaveGateLogoPayload {
  logo?: string | undefined;
  logoTitle?: string | undefined;
  logoUrl?: string;
}

export interface ISetGateSubscriptionsAction {
  payload: ISetGateSubscriptionsPayload;
  type: typeof SET_GATE_SUBSCRIPTIONS;
}

export interface ISubmitAcessCodeAction {
  payload: ISubmitAcessCodePayload;
  type: typeof SUBMIT_ACCESS_CODE;
}

interface ISetPasswordEntryValidPayload {
  channelId: string;
  valid: boolean;
}

interface ISetPasswordEntryValid {
  payload: ISetPasswordEntryValidPayload;
  type: typeof SET_PASSWORD_ENTRY_VALID;
}
interface ISetAccessCodeEntryValid {
  payload: {
    valid: boolean | null,
  };
  type: typeof SET_ACCESS_CODE_ENTRY_VALID;
}
export const submitAccessCode = (payload: ISubmitAcessCodePayload): ISubmitAcessCodeAction => ({
  payload,
  type: SUBMIT_ACCESS_CODE,
});

export const setApiLoading = (loading: boolean): ISetApiLoadingAction => ({
  payload: loading,
  type: SET_API_LOADING,
});

export const setGateSubscriptions = (payload: ISetGateSubscriptionsPayload): ISetGateSubscriptionsAction => ({
  payload,
  type: SET_GATE_SUBSCRIPTIONS,
});

export const setPasswordEntryValid = (
  channelId: string,
  valid: boolean,
): ISetPasswordEntryValid => ({
  payload: { channelId, valid },
  type: SET_PASSWORD_ENTRY_VALID,
});

export const setAccessCodeEntryValid = (
  valid: boolean | null,
): ISetAccessCodeEntryValid => ({
  payload: { valid },
  type: SET_ACCESS_CODE_ENTRY_VALID,
});
interface ISetGateActive {
  payload: {
    channelId: string;
    isActive: boolean;
  };
  type: typeof SET_IS_GATE_ACTIVE;
}

export interface ISubmitPasswordAction {
  payload: string;
  type: typeof SUBMIT_PASSWORD;
}
export const submitChannelPassword = (password: string): ISubmitPasswordAction => ({
  payload: password,
  type: SUBMIT_PASSWORD,
});

export const setGateActive = (channelId: string, isActive: boolean): ISetGateActive => ({
  payload: { channelId, isActive },
  type: SET_IS_GATE_ACTIVE,
});

interface ISetGateUserAgePayload {
  age: number;
  month: number;
  year: number;
}
interface ISetGateUserAge {
  payload: ISetGateUserAgePayload;
  type: typeof SET_GATE_USER_AGE;
}

interface ISetEnableGateErrorMessageKeyAction {
  payload: string;
  type: typeof SET_ENABLE_GATE_ERROR_MESSAGE_KEY;
}

export const setEnableGateErrorMessageKey = (payload: string): ISetEnableGateErrorMessageKeyAction => ({
  payload,
  type: SET_ENABLE_GATE_ERROR_MESSAGE_KEY,
});

export const setGateUserAge = (payload: ISetGateUserAgePayload): ISetGateUserAge => ({
  payload,
  type: SET_GATE_USER_AGE,
});

interface ISetGateTrackedPayload {
  action: string;
  docId: string;
}

interface ISetGateTracked {
  payload: ISetGateTrackedPayload;
  type: typeof SET_GATE_TRACKED;
}
export const setGateTracked = (payload: ISetGateTrackedPayload) => ({
  payload,
  type: SET_GATE_TRACKED,
});

interface ISetPreviewDuration {
  payload: ISetPreviewDurationPayload;
  type: typeof SET_PREVIEW_DURATION;
}

interface ISetPreviewDurationPayload {
  previewDurationSeconds: number;
}

export const setPreviewDuration = (payload: ISetPreviewDurationPayload) => ({
  payload,
  type: SET_PREVIEW_DURATION,
});

interface ISetPreview {
  payload: ISetPreviewPayload;
  type: typeof SET_PREVIEW;
}

interface ISetPreviewPayload {
  preview: boolean;
}

export const setPreview = (payload: ISetPreviewPayload) => ({
  payload,
  type: SET_PREVIEW,
});

interface IFetchAccountEntitlement {
  type: typeof FETCH_ACCOUNT_ENTITLEMENTS;
}

export const fetchAccountEntitlements = () => ({
  type: FETCH_ACCOUNT_ENTITLEMENTS,
});

interface ISetAccountEntitlement {
  payload: IAccountEntitlement[],
  type: typeof SET_ACCOUNT_ENTITLEMENTS,
}

export const setAccountEntitlements = (payload: IAccountEntitlement[]) => ({
  type: SET_ACCOUNT_ENTITLEMENTS,
  payload,
});

export interface IUpdateAccountEntitlement {
  payload: IAccountEntitlement;
  type: typeof UPDATE_ACCOUNT_ENTITLEMENT;
}

export const updateAccountEntitlement = (payload: IAccountEntitlement): IUpdateAccountEntitlement => ({
  type: UPDATE_ACCOUNT_ENTITLEMENT,
  payload,
});

export interface ISetAdminGatePreview {
  payload: boolean;
  type: typeof SET_ADMIN_GATE_PREVIEW;
}

export const setAdminGatePreview = (payload: boolean): ISetAdminGatePreview => ({
  type: SET_ADMIN_GATE_PREVIEW,
  payload,
});

interface ISetAccountEntitlementsLoaded {
  payload: boolean;
  type: typeof SET_ACCOUNT_ENTITLEMENTS_LOADED;
}

export const setAccountEntitlementsLoaded = (payload: boolean): ISetAccountEntitlementsLoaded => ({
  type: SET_ACCOUNT_ENTITLEMENTS_LOADED,
  payload,
});

export interface ISetAmazonBenefitCheckGatePayload {
  available: boolean;
  message: string;
}

interface ISetAmazonBenefitCheckGate {
  payload: ISetAmazonBenefitCheckGatePayload;
  type: typeof SET_AMAZON_BENEFIT_CHECK_GATE;
}

export const setAmazonBenefitCheckGate = (
  payload: ISetAmazonBenefitCheckGatePayload,
): ISetAmazonBenefitCheckGate => ({
  payload,
  type: SET_AMAZON_BENEFIT_CHECK_GATE,
});

export interface ISetAmazonBenefitRedemptionGatePayload {
  message: string;
  success: boolean;
}

interface ISetAmazonBenefitRedemptionGate {
  payload: ISetAmazonBenefitRedemptionGatePayload;
  type: typeof SET_AMAZON_BENEFIT_REDEMPTION_GATE;
}

export const setAmazonBenefitRedemptionGate = (
  payload: ISetAmazonBenefitRedemptionGatePayload,
): ISetAmazonBenefitRedemptionGate => ({
  payload,
  type: SET_AMAZON_BENEFIT_REDEMPTION_GATE,
});

interface IClearAmazonBenefitGateStatus {
  type: typeof CLEAR_AMAZON_BENEFIT_GATE_STATUS;
}

export const clearAmazonBenefitGateStatus = (): IClearAmazonBenefitGateStatus => ({
  type: CLEAR_AMAZON_BENEFIT_GATE_STATUS,
});
