import { put, select, call, take } from 'redux-saga/effects';
import {
  UPDATE_PAGE,
  updatePage,
  CLONE_PAGE,
  clonePage,
} from './actions';
import {
  setAdminMode,
  navigateToAdminBarAction,
} from 'services/admin/actions';
import { ModeTypes, ActionKey } from 'services/admin/constants';
import { getSiteId } from 'services/app/selectors';
import { IObject } from 'models';
import { fetchChannelsAndLandings, pageUpdateRequest, requestPageClone } from './api';
import { replace } from 'services/app-router';
import { pageTypes, setObject } from 'services/app';
import { makeSaga, takeEvery } from 'redux-utils';
import IState from 'services/state';
import { getPrimaryToken } from 'services/auth/selectors';
import { getIsLegacyPlan, getIsUnlimitedPlan, getPlanFeatures } from 'services/billing';
import { MAX_AMOUNT_OF_CHANNELS, MAX_AMOUNT_OF_PAGES } from 'models/IPlan';
import { ModalKinds } from 'services/modals/types';
import { showModal } from 'services/modals';
import { getDefaultNavigation, includeDuplicatedPageToDefaultNavigation, UPDATE_NAVIGATION } from 'services/navigationv2';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import { Feature } from 'services/feature-gate';
import { PAGE_SERVICE_BASE_URL, PAGE_SERVICE_V3_BASE_URL } from 'config';
import { constructPathForSlug } from 'services/navigationv2/utils';

export const updatePageSaga = makeSaga({ updatePage }, function* ({ payload: { pageId, update } }) {
  const state: IState = yield select();
  const siteId = getSiteId(state);
  const features = getActiveSiteFeatures(state);
  const primaryToken = getPrimaryToken(state);

  const pageServiceURL = features[Feature.PAGES_V3] ? PAGE_SERVICE_V3_BASE_URL : PAGE_SERVICE_BASE_URL;

  if (!primaryToken)
    return;

  yield call(pageUpdateRequest, { pageId, pageServiceURL, primaryToken, siteId, update });
});

export const clonePageSaga = makeSaga({ clonePage }, function* ({ payload: { pageId, pageName, pageSlug, type } }) {
  const state: IState = yield select();
  const siteId = getSiteId(state);
  const primaryToken = getPrimaryToken(state);
  const planFeatures = getPlanFeatures(state);
  const isLegacyPlan = getIsLegacyPlan(state);
  const isUnlimitedPlan = getIsUnlimitedPlan(state);
  const planHavePageLimits = !isLegacyPlan && !isUnlimitedPlan;
  const features = getActiveSiteFeatures(state);

  const pageServiceURL = features[Feature.PAGES_V3] ? PAGE_SERVICE_V3_BASE_URL : PAGE_SERVICE_BASE_URL;


  const maxAmountOfPages =
    type === pageTypes.LANDING
      ? planFeatures?.maxAmountOfPages || MAX_AMOUNT_OF_PAGES
      : planFeatures?.maxAmountOfChannels || MAX_AMOUNT_OF_CHANNELS;

  if (planHavePageLimits) {
    const channelsAndPages: IObject[] = yield call(fetchChannelsAndLandings, {
      pageServiceURL,
      siteId,
    });
    const amountOfPages =
      channelsAndPages?.filter((channel) => channel.type === type)?.length ||
      0;
    /**
     * Pricing V2: If the user has reached the max amount of pages, show the upgrade plan modal
     */
    if (maxAmountOfPages - amountOfPages <= 0) {
      yield put(
        showModal({
          kind: ModalKinds.upgradePlan,
          data: {
            planWarningMessage: 'ADMIN_UPGRADE_PLAN_EXCEEDED_MAX_AMOUNT_OF_CHANNELS',
          },
        }),
      );
      return;
    }
  }

  if (!primaryToken)
    return;

  const clonedPage: IObject = yield call(requestPageClone, { pageId, pageName, pageServiceURL, pageSlug, primaryToken, siteId });

  if (!clonedPage) {
    return;
  }

  yield put(setObject(clonedPage, true));
  yield put(setAdminMode(ModeTypes.EDIT));
  yield put(navigateToAdminBarAction({ actionKey: ActionKey.pageOrChannelSettings }));

  yield put(includeDuplicatedPageToDefaultNavigation(pageId, clonedPage));
  // wait for new page to be added to default navigation so we can
  // reliably compute the url path
  yield take(UPDATE_NAVIGATION);
  yield put(
    replace({
      path: constructPathForSlug(
        yield select(getDefaultNavigation),
        clonedPage.slug,
      ),
    }),
  );
});

export default function* channelAdminSaga() {
  yield takeEvery(UPDATE_PAGE, updatePageSaga);
  yield takeEvery(CLONE_PAGE, clonePageSaga);
}
