import { call, takeEvery, put, select, takeLatest, delay } from 'redux-saga/effects';
import IState from 'services/state';
import {
  chargeTipFailure,
  chargeTipSuccess,
  CHARGE_TIP,
  IChargeTipAction,
  IToggleMessageVisibilityAction,
  refreshIdempotencyKey,
  setTipSuggestedAmounts,
  TOGGLE_MESSAGE_VISIBILITY,
} from './actions';
import {
  chargeTip,
  IChargeTipParams,
  IToggleMessageVisibilityParams,
  toggleMessageVisibility,
} from './api';
import { getIdempotencyKey, getIsLoadingChargeTip, getTipAmount, getTipMessage, getTipSuggestedAmounts } from './selectors';
import { replaceWords } from 'utils';
import enProfanity from 'strings/en-profanity';
import { getUserAvatar } from 'services/user-profile';
import { getPrimaryToken, getUserName } from 'services/auth';
import { getActivePanel, getPageSlug, getSiteId, getSiteSlug } from 'services/app';
import { REFERER_URL } from 'config';
import { IRealtimeTipDocument, ITipResponse, ITipSuggestedAmounts, TipStatus } from './models';
import { getSuggestedTipAmountsByCurrency } from './utils';
import { showAdminErrorModal, showTextModal } from 'services/modals';
import { isListening } from 'services/realtime/selectors';
import { subscribe } from 'services/realtime';
import { IUpdateDocumentAction, unsubscribe, UPDATE_DOCUMENT } from 'services/realtime/actions';
import { MAXIMUM_TIP_WAIT_TIME } from './constants';
import { getStripePaymentMethod } from 'services/billing/api';
import { PaymentMethod, StripeError } from '@stripe/stripe-js';
import { getBillingInfo, ISetNativeCurrencyAction, setBillingInfoError, SET_NATIVE_CURRENCY, submitPaymentMethod } from 'services/billing';

export function* chargeTipSaga({ payload }: IChargeTipAction) {
  const state: IState = yield select();
  const amount = getTipAmount(state);
  const tipMessage = getTipMessage(state);
  const userAvatar = getUserAvatar(state) || '';
  const primaryToken = getPrimaryToken(state) || '';
  const panelId = getActivePanel(state) || '';
  const pageSlug = getPageSlug(state) || '';
  const siteSlug = getSiteSlug(state) || '';
  const idempotencyKey = getIdempotencyKey(state);
  const siteId = getSiteId(state);
  const currency = getTipSuggestedAmounts(state).currency || 'usd';

  const billingInfo = getBillingInfo(state);
  const name = getUserName(state) || '';

  const {
    paymentMethod: payloadPaymentMethod,
    element,
    stripe,
    makePrimaryPaymentMethod,
    savePaymentMethod,
  } = payload;

  let result: { error?: StripeError; paymentMethod?: PaymentMethod; } | null = { paymentMethod: payloadPaymentMethod };

  // if has element and stripe, we need to create a payment method
  if (element && stripe) {
    if (savePaymentMethod) {
      yield put(submitPaymentMethod({
        element,
        makePrimaryPaymentMethod,
        stripe,
      }));
    }

    try {
      result = yield call(getStripePaymentMethod, element, stripe, {
        ...billingInfo,
        name: billingInfo.name || name,
      });
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(e, 'error retrieving payment method from stripe');
      yield put(setBillingInfoError('Payment unsuccessful, please update your card details and try again'));
      yield put(chargeTipFailure());
      return;
    }
  }

  if (!result || !result.paymentMethod) {
    // tslint:disable-next-line: no-console
    console.error(result?.error?.message, 'error submitting payment');
    yield put(setBillingInfoError('Payment unsuccessful, please update your card details and try again'));
    yield put(chargeTipFailure());
    return;
  }

  const message: string = yield call(replaceWords, tipMessage, enProfanity, '*');
  const chargePayload: IChargeTipParams = {
    payload: {
      amount,
      billingInfo: {
        ...result.paymentMethod.billing_details,
      },
      idempotencyKey,
      message,
      panelId,
      paymentMethod: result.paymentMethod,
      presentmentCurrency: currency,
      referer: {
        origin: REFERER_URL,
        pageSlug,
      },
      siteSlug,
      userAvatar,
    },
    primaryToken,
    siteId,
  };
  try {
    const chargeResponse: ITipResponse = yield call(chargeTip, chargePayload);
    yield put(refreshIdempotencyKey());
    yield call(watchTippingStatusSaga, chargeResponse._id);
    yield call(chargeTimeoutSaga, chargeResponse._id);
  } catch(err) {
    yield put(chargeTipFailure());
  }
}

export const watchTippingStatusSaga = function* (tipId: string) {
  const state: IState = yield select();
  if (!isListening(state, 'tips', tipId)) {
    yield put(subscribe(`tips/${tipId}`));
  }
};

export const tippingStatusUpdateSaga = function* ({ payload }: IUpdateDocumentAction) {
  const { path } = payload;
  if (/^tips/.test(path) && payload.value) {
    const value: IRealtimeTipDocument = payload.value;
    switch (value.status) {
      case TipStatus.ACTIVE: {
        yield put(chargeTipSuccess());
        break;
      }
      case TipStatus.CANCELED: {
        yield put(showAdminErrorModal('DYNAMIC_ERROR_MESSAGE', value.errorMessage || 'Unable to process payment, please try again'));
        yield put(chargeTipFailure());
        break;
      }
    }
  }
};

export function* chargeTimeoutSaga(tipId: string) {
  yield delay(MAXIMUM_TIP_WAIT_TIME);
  const state: IState = yield select();
  const isLoading: boolean = yield select(getIsLoadingChargeTip);
  if (isLoading) {
    yield put(chargeTipFailure());
  }
  if (isListening(state, 'tips', tipId)) {
    yield put(unsubscribe(`tips/${tipId}`));
  }
}

export function* toggleMessageVisibilitySaga({ payload }: IToggleMessageVisibilityAction) {
  const state: IState = yield select();
  const primaryToken = getPrimaryToken(state) || '';
  const siteId = getSiteId(state);
  const { id, panelId, toggle } = payload;

  const togglePayload: IToggleMessageVisibilityParams = {
    payload: {
      id,
      panelId,
      toggle,
    },
    primaryToken,
    siteId,
  };

  try {
    yield call(toggleMessageVisibility, togglePayload);
  } catch(error) {
    yield put(showTextModal('An error occurred. Please try again.'));
  }
}

export function* handleTipSuggestedAmountsSaga({ payload }: ISetNativeCurrencyAction) {
  const result: ITipSuggestedAmounts = yield call(getSuggestedTipAmountsByCurrency, payload);
  yield put(setTipSuggestedAmounts(result));
}

export default function* TippingSaga() {
  yield takeEvery(CHARGE_TIP, chargeTipSaga);
  yield takeEvery(SET_NATIVE_CURRENCY, handleTipSuggestedAmountsSaga);
  yield takeEvery(TOGGLE_MESSAGE_VISIBILITY, toggleMessageVisibilitySaga);
  yield takeLatest(UPDATE_DOCUMENT, tippingStatusUpdateSaga);
}
