import { createSelector } from 'reselect';
import { RawDraftContentBlock } from 'draft-js';
import { getPageId } from 'services/app/selectors';
import { isUserLoggedIn, isUserSuperAdmin, isUserAdmin, isUserChatModeratorRole } from 'services/auth/selectors';
import { getWatchedEntitlementIds } from 'services/billing/selectors';
import { getHeight, isDesktopLayout } from 'services/device/selectors';
import { getDocument } from 'services/realtime/selectors';
import { hasGdpr } from 'services/modals/selectors';
import { getActiveAction } from 'services/admin/selectors';
import IState from 'services/state';
import { HEADER_HEIGHT, SPACING_LARGE_PX, SPACING_MEDIUM_PX, TERMS_OF_USE_HEIGHT_PX } from 'style/constants';
export * from './common';
import { EMPTY_ARRAY } from 'services/utils';
import ISubscription from 'models/ISubscription';
import IBundle from 'models/IBundle';
import {
  AccountEntitlementStatus,
  IAccountEntitlement,
} from '../models';
import IGate, {
  GateKind,
  ISubscriptionData,
  IMaxPreviewDuration,
  defaultGateHeaderData,
} from 'models/IGate';
import { ActionKey } from 'services/admin/constants';
import { DEFAULT_GATE_SUBTITLE } from 'shared/constants';
import { isDraftJSRawData } from 'utils';
import { injectT, TranslationKey } from 'hooks/use-translation';
import { lazy, lazyFn } from 'utils/lazy';
import { AmazonBenefitData } from 'components/admin2/AccessControlModal/AmazonBenefitGateSettings';
import { USE_AMAZON_SERIES_ID } from 'config';

const EMPTY_STRING = '';

// TODO: Check where we are using this an replace for new gate selector
export const getCurrentGateData = ({ app }: IState): IGate => (
  // @ts-ignore: TODO: Delete this after implement type definition
  app.object?.data?.gate
);

const getGateSubscriptions = createSelector(
  [getCurrentGateData],
  (data: IGate): ISubscription[] => data?.gate?.subscriptions || [],
);

const getGateBundles = createSelector(
  [getCurrentGateData],
  (data: IGate): IBundle[] => data?.gate?.bundles || [],
);

export const getChannelAmazonBenefitData = createSelector(
  [getCurrentGateData],
  (data: IGate): AmazonBenefitData => {
    const amazonBenefitData = data?.gate?.amazonBenefitData;

    return data?.gate?.amazonBenefitData ||
      {
        amazonBenefitId: '',
        amazonBenefitPurchaseUrl: '',
        benefitType: 'single',
        seriesBenefitMapping: [],
      };
  },
);

export const getChannelAmazonBenefitId = createSelector(
  [getCurrentGateData],
  (data: IGate): string | null => {
    const amazonBenefitData = data?.gate?.amazonBenefitData||
    {
      amazonBenefitId: '',
      amazonBenefitPurchaseUrl: '',
      benefitType: 'single',
      seriesBenefitMapping: [],
    };

    if(amazonBenefitData &&
      amazonBenefitData.benefitType === 'multiple' &&
      amazonBenefitData.seriesBenefitMapping.length > 0 &&
      USE_AMAZON_SERIES_ID) {
        const seriesBenefits =
          amazonBenefitData.seriesBenefitMapping?.find((series) => {
          return series.seriesId === USE_AMAZON_SERIES_ID;
        });
        return seriesBenefits?.amazonBenefitId || '';
    }

    if(amazonBenefitData &&
      amazonBenefitData.benefitType === 'single' &&
      amazonBenefitData.amazonBenefitId) {
        return amazonBenefitData.amazonBenefitId;
    }

    return null;
  },
);

export const getSubscriptionContentHeight = createSelector(
  [getHeight, isDesktopLayout, hasGdpr], (height, isDesktop, gdprActive) => {
    let deviceHeight = height;
    if (isDesktop) {
      deviceHeight -= HEADER_HEIGHT; // header height
      deviceHeight -= SPACING_MEDIUM_PX; // top padding
      if (gdprActive) {
        deviceHeight -= TERMS_OF_USE_HEIGHT_PX;
      } else {
        deviceHeight -= SPACING_LARGE_PX; // bottom padding
      }
    }
    return deviceHeight;
  },
);

export const getWatchedEntitlements = createSelector((state: IState): IAccountEntitlement[] => {
  const ids = getWatchedEntitlementIds(state);
  if (ids.length === 0) { return EMPTY_ARRAY; }
  return ids.map((id: string) => {
    return getDocument(state, 'entitlementaccess', id) || {};
  });
}, ids => ids);

export const getFixedGateData = injectT(
  (t) => lazy(
    () => (state: IState): ISubscriptionData => {
      const data = getCurrentGateData(state);
      const background = data?.gate?.background;
      const bundles = getGateBundles(state);
      const subscriptions = getGateSubscriptions(state);
      const titleValue = data?.gate?.title || data?.gate?.subscriptionTitle;
      const logoLink = data?.gate?.logoLink;
      const logoUrl = data?.gate?.logoUrl || defaultGateHeaderData(t).logoUrl;
      const logoTitle = data?.gate?.logoTitle || defaultGateHeaderData(t).logoTitle;
      const navigation = data?.gate?.navigation || {
        ...defaultGateHeaderData(t).navigation,
        items: [...defaultGateHeaderData(t).navigation.items ],
      };
      const titleRawData = data?.gate?.titleRawData;
      const titleRawDataV2 = data?.gate?.titleRawDataV2 || '';

      const getSubTitleValue = () => {
        if (data?.gate?.subtitle === DEFAULT_GATE_SUBTITLE(t)) {
          return EMPTY_STRING;
        }
        return data?.gate?.subtitle || data?.gate?.subscriptionSubtitle;
      };

      const getSubTitleRawData = () => {
        const subtitleData = data?.gate?.subtitleRawData;
        let isDefaultData = false;

        // We just show default subtitle to admin users.
        if (subtitleData) {
          // RawData is deprecated since NS-6418. It is just a support for legacy data
          if (isDraftJSRawData(subtitleData)) {
            const subtitleRaw = JSON.parse(data.gate.subtitleRawData) as {
              blocks: RawDraftContentBlock[];
            };
            isDefaultData =
              subtitleRaw.blocks[0] &&
              subtitleRaw.blocks[0].text === DEFAULT_GATE_SUBTITLE(t);
          } else {
            // It is HTML DATA
            isDefaultData = subtitleData.includes(DEFAULT_GATE_SUBTITLE(t));
          }
        }
        return isDefaultData ? EMPTY_STRING : subtitleData;
      };

      const getSubtitleRawDataV2 = () => {
        const subtitleData = data?.gate?.subtitleRawDataV2;
        const isDefaultData = subtitleData?.includes(DEFAULT_GATE_SUBTITLE(t)) || false;
        return isDefaultData ? EMPTY_STRING : subtitleData || EMPTY_STRING;
      };

      const subtitleValue = getSubTitleValue();
      const subtitleRawData = getSubTitleRawData();
      const subtitleRawDataV2 = getSubtitleRawDataV2();

      return {
        hiddenEntitlements: data?.gate?.hiddenEntitlements || [],
        hiddenBundles: data?.gate?.hiddenBundles || [],
        bundles,
        subscriptions,
        subtitle: subtitleValue,
        subtitleRawDataV2,
        timestamp: data?.gate?.subscriptionTimestamp,
        logoLink,
        logoUrl,
        logoTitle,
        navigation,
        title: titleValue,
        titleRawData,
        titleRawDataV2,
        subtitleRawData,
        background,
      };
    },
  ),
);

export const getGatePreview = ({ gate }: IState): boolean => gate.preview || false;
export const getGatePreviewDuration = ({ gate }: IState): number => gate.previewDurationSeconds || 0;

export const getMaxPreviewDuration = (state: IState): IMaxPreviewDuration => {
  const subscriptions = getGateSubscriptions(state);
  return subscriptions.reduce((currentDuration: any, currentSubscription) => {
    const subscriptionDuration = currentSubscription?.previewDurationSeconds || 0;
    return currentDuration.previewDurationSeconds > subscriptionDuration ? currentDuration : { previewDurationSeconds: subscriptionDuration };
  }, { previewDurationSeconds: 0 });
};

export const getAccountEntitlements = createSelector(({ gate }: IState): IAccountEntitlement[] => gate.accountEntitlements, accountEntitlements => accountEntitlements);

export const userHasSubscriptions = injectT(
  (t) => lazy(
    () => createSelector([
      getFixedGateData(t),
      getAccountEntitlements,
      isUserLoggedIn,
      isUserSuperAdmin,
      isUserAdmin,
      isUserChatModeratorRole,
      getWatchedEntitlements,
    ], (
      { bundles, subscriptions },
      userSubscriptions,
      loggedIn,
      superAdmin,
      userAdmin,
      chatModerator,
      entitlements,
    ) => {

      // user can access if there are no subscriptions or bundles
      if (!(subscriptions && subscriptions.length) && !(bundles && bundles.length)) {
        return true;
      }

      // user is not logged in
      if (!(loggedIn)) {
        return false;
      }

      // skip for admins or chat moderators
      if (superAdmin || userAdmin || chatModerator) {
        return true;
      }

      // check that user has ANY of the subscriptions listed
      for (const { sku } of subscriptions) {

        for (const { entitlement, status } of entitlements) {
          if (
            entitlement?.sku === sku &&
            status !== AccountEntitlementStatus.canceled &&
            status !== AccountEntitlementStatus.expired
          ) {
            return true;
          }
        }

        for (const { entitlement, status } of userSubscriptions) {
          if (
            entitlement?.sku === sku &&
            status !== AccountEntitlementStatus.canceled &&
            status !== AccountEntitlementStatus.expired
            ) {
            return true;
          }
        }

      }

      // all tickets' ids of all bundles
      const bundleTicketsIds = bundles.map(({ items }) => {
        return items.filter(({ type }) => type === 'ticket').map(({ id }) => id); // all tickets' ids of a bundle
      }).flat();

      // check that user has ANY of the bundle tickets listed
      for (const ticketId of bundleTicketsIds) {

        for (const { entitlement, status } of entitlements) {
          if (entitlement?._id === ticketId && status !== AccountEntitlementStatus.canceled) {
            return true;
          }
        }

        for (const { entitlement, status } of userSubscriptions) {
          if (entitlement?._id === ticketId && status !== AccountEntitlementStatus.canceled) {
            return true;
          }
        }

      }

      return false;
    }),
  ),
);

export const getChannelGateKind = createSelector([getCurrentGateData], (gate) => {
  return gate?.kind || null;
});
export const gateIsSubscription = createSelector([getCurrentGateData], (gate) => {
  return gate?.kind === GateKind.SUBSCRIPTION || gate.kind === GateKind.ACCESSCODE;
});
export const getChannelGateLoginMessage = createSelector([getCurrentGateData], (gate) => {
  return gate?.gate?.loginMessage || null;
});
export const isPasswordEntryValid = (state: IState): boolean | null => {
  const channelId = getPageId(state);
  if (!channelId) {
    return null;
  }
  const passwordEntryState = state.gate.passwordEntryValid[channelId];
  if (typeof passwordEntryState === 'boolean') {
    return passwordEntryState;
  }
  return null;
};
export const amazonBenefitGateInfo = ({ gate }: IState) => gate.amazonBenefitGate;
export const isAccessCodeEntryValid = ({ gate }: IState) => gate.accessCodeEntryValid;
export const getGateAgeVerificationAge = ({ gate }: IState) => gate.userAge;
export const getGateTrackedData = ({ gate }: IState) => gate.gateTracked;
export const getApiLoading = ({ gate }: IState) => gate.apiLoading;
export const isGateActive = createSelector(
  [
    isUserAdmin,
    ({ app, gate }: IState) => {
      if (gate.adminGatePreview) {
        return true;
      }
      const channelId = app.object?._id;
      const gateEnabled = app.object?.data?.gate?.active || false;
      if (gateEnabled) {
        return [undefined, true].includes(gate.activeGateMap[channelId]);
      }
      return false;
    },
    ({ gate }: IState) => gate.adminGatePreview,
  ],
  (isAdmin, gateActive, isGatePreviewEnabled) =>
    (!isAdmin || isGatePreviewEnabled) && gateActive,
);

export const getActiveRecurringSubscriptions = createSelector((state: IState): IAccountEntitlement[] => {
  const entitlements = getAccountEntitlements(state) || [];
  if (entitlements.length === 0) return EMPTY_ARRAY;

  return entitlements.filter(items => {
    return items.status === 'active' && items.entitlement && items.entitlement.recurrence !== 'once';
  });
}, entitlements => entitlements);

export const isAdminViewingAccessTab = lazyFn(
  () => createSelector([
    isUserAdmin,
    getActiveAction,
  ], (
    isAdmin,
    activeAction,
  ) => {
    return isAdmin && activeAction === ActionKey.access;
  }),
);

export const getEnableGateErrorMessageKey = ({ gate }: IState) => gate.enableGateErrorMessageKey as TranslationKey;
