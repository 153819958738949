// tslint:disable:no-console
import { makeSaga } from 'redux-utils';
import { put } from 'redux-saga/effects';
import {
  navigateToAdminBarAction,
  setActiveTab,
} from 'services/admin/actions';
import { setStudioSessionReady } from '../actions';

export const resetStudioSessionSaga = makeSaga(
  { navigateToAdminBarAction, setActiveTab },
  function* () {
    try {
      yield put(setStudioSessionReady(false));
    } catch (error) {
      console.error('resetStudioSessionSaga:', error);
    }
  },
);
