import INavigation, { INavigationChild, INavigationItem, INavigationParent, NAVIGATION_PARENT_TYPE, NavigationChildType, NavigationParentType, navigationChildTypeToNavigationParentType } from 'models/INavigation';
import { dismissModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { updateNavigation } from './actions';
import { slugify } from 'shared/string-utils';

const FOLDER_NAME_MIN_LENGTH = 3;
export const NAV_ITEM_NAME_MAX_LENGTH = 25;

export const NAV_NAME_MIN_LENGTH = 3;
export const NAV_NAME_MAX_LENGTH = 25;

export const findPageInNavigation = (navigation: INavigation, pageId: string) => {
  // Look for the page among parents
  for (const parent of navigation.parents) {
    if (parent.id === pageId) {
      return { found: true, isParent: true, item: parent };
    }

    // Look for the page among the children of the current parent
    for (const child of parent.children) {
      if (child.id === pageId) {
        return { found: true, isParent: false, item: child };
      }
    }
  }
};

export const getCustomNavigationName = (navigations: INavigation[], namePrefix: string) => {
  const baseExists = navigations.some(item => item.name === namePrefix);
  if (!baseExists) {
    return namePrefix;
  }

  let numberSuffix = 1;
  let newName = '';

  let freeName = false;
  while (!freeName) {
    numberSuffix++;
    newName = `${namePrefix} ${numberSuffix}`;
    const isThereANavigationWithThisName = navigations.some((item) => item.name === newName);
    freeName = !isThereANavigationWithThisName;
  }
  return newName;
};

interface IMakeParentAndChildHashMap {
  childHashMap: Map<string, INavigationChild>;
  parentHashMap: Map<string, INavigationParent>;
}

export const makeParentAndChildHashMap = (navigation: INavigation): IMakeParentAndChildHashMap => {
  const parentHashMap = new Map();
  const childHashMap = new Map();
  const { parents } = navigation;

  for (const parent of parents) {
    const { id: parentId, children } = parent;
    parentHashMap.set(parentId, parent);

    for (const child of children) {
      const { id: childId } = child;
      childHashMap.set(childId, child);
    }
  }

  return {
    parentHashMap,
    childHashMap,
  };
};

export const mapNavigationItemTypeToIcon = (type: NavigationParentType | NavigationChildType) => {
  if (type === NAVIGATION_PARENT_TYPE.folder) {
    return 'folderOutline';
  }
  if (type === NAVIGATION_PARENT_TYPE.channel) {
    return 'channelSelectV3Outline';
  }

  return 'pageOutline';
};

export const getFolderName = (navigation: INavigation, baseName: string, itemId?: string) => {
  const baseSlug = slugify(baseName);
  let uniqueSlug = baseSlug;
  let uniqueName = baseName;

  const slugExists = (slug: string) => {
    const existOnAParent = navigation.parents.some(parent => parent.slug === slug && parent.id !== itemId);
    const existOnAChild = navigation.parents.some(parent => parent.children.some(child => child.slug === slug && child.id !== itemId));
    return existOnAParent || existOnAChild;
  };

  if (!slugExists(baseSlug)) {
    return uniqueName;
  }

  let numberSuffix = 1;
  do {
    numberSuffix++;
    uniqueSlug = `${baseSlug}-${numberSuffix}`;
    uniqueName = `${baseName} ${numberSuffix}`;
  } while (slugExists(uniqueSlug));

  return uniqueName;
};

const cleanString = (text: string) => {
  // remove any character that is not alphanumeric, space, or hyphen
  return text.replace(/[^a-zA-Z0-9 -]/g, '');
};

export const treatNavItemName = (name: string) => {
  // trim, remove special characters and limit the length
  return cleanString(name).trim().slice(0, NAV_ITEM_NAME_MAX_LENGTH).trim();
};

export const treatFolderRenaming = (folderName: string) => {
  const name = treatNavItemName(folderName);
  if (name.length === 0) {
    return 'New Folder';
  }
  if (name.length < FOLDER_NAME_MIN_LENGTH) {
    return `Folder ${name}`;
  }
  return name;
};

export const updateNavigationItem = (navigation: INavigation, newItem: INavigationItem): INavigation => {
  // keep in mind that an item can be a parent or a child
  const updatedParents = navigation.parents.map(parent => {
    // check if the parent is the item to update
    if (parent.id === newItem.id) {
      return {
        ...parent,
        ...newItem as INavigationParent,
      };
    }

    // check if the item to update is a child of the current parent
    const updatedChildren = parent.children.map(child => {
      if (child.id === newItem.id) {
        return {
          ...child,
          ...newItem as INavigationChild,
        };
      }
      return child;
    });

    return {
      ...parent,
      children: updatedChildren,
    };
  });

  return {
    ...navigation,
    parents: updatedParents,
  };
};

export const removeItemFromNavigation = (navigation: INavigation, itemId: string): INavigation => {
  const parentToBeRemoved = navigation.parents.find((parent) => parent.id === itemId);
  if (parentToBeRemoved) {
    navigation.parents = [ // move children to root (parents level)
      ...navigation.parents,
      ...parentToBeRemoved.children.map((child) => ({
        ...child,
        type: navigationChildTypeToNavigationParentType[child.type],
        children: [],
      })),
    ];
    navigation.parents = navigation.parents.filter((parent) => parent.id !== itemId); // remove the parent
    return navigation;
  }
  const parentThatContainsChildToBeRemoved = navigation.parents.find((parent) => {
    return parent.children.some((child) => child.id === itemId); // assumes a child can only have one parent
  });
  if (parentThatContainsChildToBeRemoved) {
    parentThatContainsChildToBeRemoved.children = parentThatContainsChildToBeRemoved.children.filter((child) => {
      return child.id !== itemId; // remove the child
    });
    return navigation;
  }
  return navigation; // if it reaches here, the item was not in the navigation
};

export const showConfirmationModal = (
  navigation: INavigation,
  titleKey: string,
  subtitleKey: string,
  dispatch: React.Dispatch<any>,
) => {
  dispatch(
    showModal({
      kind: ModalKinds.adminConfirmation,
      data: {
        onConfirmClick: () => {
          dispatch(updateNavigation(navigation));
          dispatch(dismissModal('adminConfirmation'));
        },
        titleKey: titleKey as any,
        subtitleKey: subtitleKey as any,
      },
    }),
  );
};

export const onConfirmChangeForNavigationBlock = ({ destinationParent, dragItem }) => {
  if (destinationParent?.type === NAVIGATION_PARENT_TYPE.channel) {
    // channel is receiving a child. do NOT allow it. channels can NOT have children
    return false;
  }
  if (dragItem.type === NAVIGATION_PARENT_TYPE.folder && destinationParent !== null) {
    // folder is being dragged into a parent. do NOT allow it. folders can NOT be children
    return false;
  }
  return true;
};

export const hidePrivatePagesFromNavigation = (navigation: INavigation): INavigation => {
  return {
    ...navigation,
    parents: navigation.parents
      .filter((parent) => !parent.private)
      .map((parent) => ({
        ...parent,
        children: parent.children.filter((child) => !child.private),
      })),
  };
};

export const constructPathForSlug = (defaultNavigation: INavigation, slug: string): string => {
  for (const parent of defaultNavigation.parents) {
    const foundChild = parent.children.find((node) => node.slug === slug);

    if (foundChild) {
      return `/${parent.slug}/${foundChild.slug}`;
    }
  }

  return `/${slug}`;
};

export const getPageSlugById = (navigation: INavigation, pageId: string ): string => {
  const { parents } = navigation;

  for (const parent of parents) {
    if (parent.id === pageId) {
      return parent.slug;
    }

    for (const child of parent.children) {
      const { id: childId, slug: childSlug } = child;

      if (childId === pageId) {
        return childSlug;
      }
    }
  }

  return '';
};
